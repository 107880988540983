import React from 'react';
import { Titulek } from '../prvky/Titulek';

export function Kontakt() {
    return <section className="kontakt">
        <div className="proNavigaci proMobil" id="kontakt" />
        <Titulek nazev="Kontakt" />
        <p>
            <a className='bold' href="tel:+420739205347">+420 739 205 347</a>
        </p>
        <p>
            <a className='bold' href="mailto:info@impactczechawards.cz">info@impactczechawards.cz</a>
        </p>
        <div className="flexboxKontakt">
            <div className="itemKontakt">
                <img className="fotoramecek" src="images/lidi/tomashajek.jpg" alt="" />
                <div className="kontaktTexty">
                    <div className="jmeno">Tomáš Hájek</div>
                    <p className="bold">Projektový manažer</p>
                </div>
            </div>
            <div className="itemKontakt">
                <img className="fotoramecek" src="images/lidi/petrsimek.jpg" alt="" />
                <div className="kontaktTexty">
                    <div className="jmeno">Petr Šimek</div>
                    <p className="bold">Ředitel soutěže</p>
                </div>
            </div>
        </div>
    </section>
}