import React from 'react';
/* import {UvodPozadi} from '../casti/UvodPozadi'; */
import {isMobile} from '../util';

export function Uvod() {
    return <section id="uvod" className="uvod">
        <div className="proNavigaci"/>
        <div id="hlavickaUvodFoto">
        {isMobile ? <>
                <img src="images/hlavicky/Tablet_790x919.jpg" alt="" />
            </> : <>
            <img src="images/hlavicky/Desktop_1920x960.jpg" alt="" />
            </>}
        </div>
       {/* <div id="hlavickaUvod">
            <div className="carkovany">
                <div className="levyhorni ctverec"/>
                <div className="levydolni ctverec"/>
                <div className="horni ctverec"/>
                <div className="dolni ctverec"/>
                <div className="pravyhorni ctverec"/>
                <div className="pravydolni ctverec"/>
                <h1>
                    Vydlážděte <br className="proMobil"/>
                    si
                    <br className="proDesktop"/> cestu <br className="proMobil"/>
                    do Evropy
                </h1>
            </div>
            {isMobile ? <>
                <h3 style={{whiteSpace: 'nowrap'}}>Soutěž s posláním</h3>
                <div className="button">
                    <a href="/">16 kategorií = 16 šancí zvítězit</a>
                </div>
            </> : <>
                <h3>
                    Zabodujte doma a&nbsp;vstupte
                    <br/>
                    do evropské soutěže
                </h3>
                <div className="button">
                    <a
                        href="https://www.imcczechawards.cz/"
                        target="_blank"
                        rel="noreferrer">
                        Registrace
                    </a>
                </div>
            </>}
        </div>
        <UvodPozadi/> */}
    </section>
}