/**
 * @file
 * Tady v tom souboru už je ta opravdová Reactí sranda.
 */

import React from 'react';
import './App.css'
import {BrowserRouter, Route, Routes,} from 'react-router-dom';
import {Hlavicka} from './casti/Hlavicka';
import {Paticka} from './casti/Paticka';
import {Uvod} from './stranky/Uvod';
import {Kategorie} from './stranky/Kategorie';
import {Porota} from './stranky/Porota';
import {Upoutavka} from './casti/Upoutavka';
import {Terminy} from './stranky/Terminy';
import {Pravidla} from './stranky/Pravidla';
import {Kontakt} from './stranky/Kontakt';
import {isMobile} from './util';
import {Galerie} from './stranky/Galerie';
import {Vysledky} from './stranky/Vysledky';
import {Vysledky2023} from './stranky/Vysledky2023';
import {Vysledky2022} from './stranky/Vysledky2022';
import {Vysledky2021} from './stranky/Vysledky2021';
import {ONas} from './stranky/ONas';
import {Soutez} from './stranky/Soutez';

/**
 * Základní komponenta celé React  aplikace. Název může být libovolný, tady je to App.
 * Komponenty jsou stavební prvky Reactu. Generují HTML a obsahují i JS, který dává elementům funkce.
 * V Reactu jsou dva způsoby jak definovat komponenty: https://www.w3schools.com/react/react_components.asp
 * My využíváme functional components - novější a za mě výrazně lepší. Myslím, že je i doporučovaný samotným Reactem.
 */
export function App() {
    return isMobile ?
        <MobileApp/> :
        <DesktopApp/>
}

function DesktopApp() {
    /**
     * Tohle jsou komponenty, které umožní routování - zobrazování různého obsahu při různém URL
     */
    return <BrowserRouter>
        <Hlavicka/>
        <Routes>
            <Route path="/" element={<Uvod/>}/>
            <Route path="/galerie/*" element={<Galerie/>}/>
            <Route path="/vysledky/*" element={<Vysledky/>}/>
            <Route path="/vysledky2023/*" element={<Vysledky2023/>}/>
            <Route path="/vysledky2022/*" element={<Vysledky2022/>}/>
            <Route path="/vysledky2021/*" element={<Vysledky2021/>}/>
            <Route path="/kategorie/*" element={<Kategorie/>}/>
            <Route path="/kontakt/*" element={<Kontakt/>}/>
            <Route path="/onas/*" element={<ONas/>}/>
            <Route path="/pravidla/*" element={<Pravidla/>}/>
            <Route path="/soutez/*" element={<Soutez/>}/>
            <Route path="/terminy/*" element={<Terminy/>}/>
            <Route path="/porota/*" element={<Porota/>}/>
        </Routes>
        <Paticka/>
    </BrowserRouter>
}

function MobileApp() {
    return <>
        <Hlavicka/>
        <Uvod/>
        <Upoutavka/>
        <Terminy/>
        <Kategorie/>
        <Pravidla/>
        <Kontakt/>
        <Paticka/>
    </>
}