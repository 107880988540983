import React from 'react';


export function Vysledky2023() {
    return <section className='vysledky'>
        <div className="bezTitulek"></div>
        <div className="container">
            <div className="flexboxVysledky">
                <div className="vysledkyRocnik">2023</div>

            </div>
            <div className="flexboxVysledky">
                <table className='vysledkySpecial'><tr><td>Klient ročníku</td></tr>
                    <tr><td>Plzeňský Prazdroj</td></tr></table>
                <table className='vysledkySpecial'><tr><td>Agentura ročníku</td></tr>
                    <tr><td>TRIAD Advertising</td></tr></table>
            </div>
            <table>
                <tr><td>Název projektu</td><td>Přihlašovatel / spolupracovníci</td><td>Klient</td><td>Kategorie</td><td>Ocenění</td></tr>

                <tr><td>13: CEST</td><td>McCann Prague / Hero & Outlaw</td><td>Česká asociace pojišťoven</td><td></td><td><span className='oranzovy bold'>GRAND PRIX</span>
                </td></tr>

                <tr className='seda'><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Radost z jídla doručena</td><td>TRIAD Advertising / WeAre productions, Luboš Rezler</td><td>Wolt</td><td>Budování značky</td><td><span className='oranzovy bold'>zlato</span>
                </td></tr>

                <tr><td>Studentský účet G2 - "Když je to poprvé"</td><td>Momentum Czech Republic</td><td>Komerční banka</td><td>Budování značky</td><td><span className='oranzovy bold'>zlato</span>
                </td></tr>

                <tr><td>Životní pojištění Simplea</td><td>Momentum Czech Republic</td><td>Simplea pojišťovna</td><td>Budování značky</td><td><span className='oranzovy bold'>stříbro</span>
                </td></tr>

                <tr><td>Rezervační systém s příběhem</td><td>Agentura Gen</td><td>Žilka Optik Studio</td><td>Budování značky</td><td><span className='oranzovy bold'>bronz</span>
                </td></tr>

                <tr className='seda'><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Digitalizace retailu</td><td>KNOWINSTORE</td><td>COOP a Super zoo</td><td>Digitální inovace v retailu</td><td><span className='oranzovy bold'>stříbro</span>
                </td></tr>

                <tr className='seda'><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Nestydatě šťavnaté novinky od Birellu</td><td>TRIAD Advertising / Bison & Rose, Silencio</td><td>Plzeňský prazdroj</td><td>Digitální trefa</td><td><span className='oranzovy bold'>zlato</span>
                </td></tr>

                <tr><td>Vodafone – Roztočte Vary po svém</td><td>McCann Prague</td><td>Vodafone Česká republika</td><td>Digitální trefa</td><td><span className='oranzovy bold'>stříbro</span>
                </td></tr>

                <tr><td>Životní pojištění Simplea</td><td>Momentum Czech Republic</td><td>Simplea pojišťovna</td><td>Digitální trefa</td><td><span className='oranzovy bold'>bronz</span>
                </td></tr>

                <tr className='seda'><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Livesport Extreme Coding Challenge</td><td>TRIAD Advertising / Yezede</td><td>Livesport</td><td>Event, field a sportovní marketing</td><td><span className='oranzovy bold'>zlato</span></td></tr>

                <tr><td>Národní den bez spěchu</td><td>McCann Prague / Hero & Outlaw, IPG Mediabrands CZ</td><td>Česká asociace pojišťoven</td><td>Event, field a sportovní marketing</td><td><span className='oranzovy bold'>stříbro</span></td></tr>

                <tr><td>Vítejte ve světě eMobility</td><td>Etnetera Motion / IMA PRODUCTION</td><td>Škoda Auto</td><td>Event, field a sportovní marketing</td><td><span className='oranzovy bold'>bronz</span></td></tr>

                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Kolegové pro lepší život v Pojišťovně Kooperativa</td><td>Agentura Gen</td><td>Pojišťovna Kooperativa - Nadace Kooperativa</td><td>Charitativní a neziskový marketing</td><td><span className='oranzovy bold'>stříbro</span></td></tr>

                <tr><td>With Love for D.</td><td>Etnetera Motion</td><td>Škoda Auto</td><td>Charitativní a neziskový marketing</td><td><span className='oranzovy bold'>bronz</span></td></tr>

                <tr><td>Radegast - Vezmi si na hrb boj o vodu</td><td>Boomerang Communication</td><td>Plzeňský Prazdroj - RADEGAST</td><td>Charitativní a neziskový marketing</td><td><span className='oranzovy bold'>bronz</span></td></tr>

                <tr><td>Slaný kalendář 2023</td><td>Elite BTW</td><td>E.ON<br></br>Klub nemocných cystickou fibrózou</td><td>Charitativní a neziskový marketing</td><td><span className='oranzovy bold'>bronz</span></td></tr>

                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>13: CEST</td><td>McCann Prague / Hero & Outlaw</td><td>Česká asociace pojišťoven</td><td>Influencer marketing</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Reakce Bageterie Boulevard na spot McDonalds - Martin Morejt</td><td>Engine Room / Bageterie Boulevard, Martin Mikyska</td><td>Bageterie Boulevard</td><td>Influencer marketing</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Nestydatě šťavnaté novinky od Birellu</td><td>TRIAD Advertising / Bison & Rose, Silencio</td><td>Plzeňský prazdroj</td><td>Inovativní myšlenka nebo koncept</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Vítejte v Kopakabráně!</td><td>TRIAD Advertising / Publicis, City Street Games</td><td>Plzeňský prazdroj</td><td>Inovativní myšlenka nebo koncept</td><td><span className="oranzovy bold">stříbro</span></td></tr>

                <tr><td>System Sho(p)k</td><td>McCann Prague</td><td>Všeobecná zdravotní pojišťovna</td><td>Inovativní myšlenka nebo koncept</td><td><span className="oranzovy bold">bronz</span></td></tr>

                <tr><td>Doručujeme pěkný den</td><td>Momentum Czech Republic</td><td>Direct Parcel Distribution CZ</td><td>Inovativní myšlenka nebo koncept</td><td><span className="oranzovy bold">bronz</span></td></tr>

                <tr><td>Index prosperity a finančního zdraví</td><td>Digital First Marketing Group / Insighters</td><td>Česká spořitelna </td><td>Inovativní myšlenka nebo koncept</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Vítejte v Kopakabráně!</td><td>TRIAD Advertising / Publicis, City Street Games</td><td>Plzeňský prazdroj</td><td>Integrovaná komunikace</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Studentský účet G2 - „Když je to poprvé“</td><td>Momentum Czech Republic</td><td>Komerční banka</td><td>Integrovaná komunikace</td><td><span className="oranzovy bold">stříbro</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Národní den bez spěchu</td><td>McCann Prague / Hero & Outlaw, IPG Mediabrands CZ</td><td>Česká asociace pojišťoven</td><td>Nízkorozpočtové kampaně</td><td><span className="oranzovy bold">zlato</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Radost z jídla doručena</td><td>TRIAD Advertising / WeAre Production, Luboš Rezler</td><td>Wolt</td><td>Produktový launch, relaunch nebo trial</td><td><span className="oranzovy bold">stříbro</span></td></tr>

                <tr><td>Studentský účet G2 - „Když je to poprvé“</td><td>Momentum Czech Republic</td><td>Komerční banka</td><td>Produktový launch, relaunch nebo trial</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>První direct B2B mailing na světě</td><td>Wunderman Thompson </td><td>Wunderman Thompson</td><td>Přímá B2B a B2C komunikace</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Jak Design Shop pomáhá prodávat pochopení modernímu umění</td><td>WELLEN </td><td>Kunsthalle</td><td>Retail koncept</td><td><span className="oranzovy bold">stříbro</span></td></tr>

                <tr><td>Lokalizovaný retail design prodává na Vinohradech lépe</td><td>WELLEN</td><td>Vodafone</td><td>Retail koncept</td><td><span className="oranzovy bold">stříbro</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>13: CEST</td><td>McCann Prague / Hero & Outlaw</td><td>Česká asociace pojišťoven</td><td>Sociální média a brandový obsah</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Podcast On Ria</td><td>SYMBIO Digital</td><td>HARTMANN - RICO</td><td>Sociální média a brandový obsah</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Livesport Extreme Coding Challenge</td><td>TRIAD Advertising / Yezede</td><td>Livesport</td><td>Sociální média a brandový obsah</td><td><span className="oranzovy bold">stříbro</span></td></tr>

                <tr><td>Studentský účet G2 - „Když je to poprvé“</td><td>Momentum Czech Republic</td><td>Komerční banka</td><td>Sociální média a brandový obsah</td><td><span className="oranzovy bold">bronz</span></td></tr>

                <tr><td>IG Sládci v Akci</td><td>WeDigital</td><td>Pilsner Urquell</td><td>Sociální média a brandový obsah</td><td><span className="oranzovy bold">bronz</span></td></tr>
                <tr className="seda"><td></td><td></td><td></td><td></td><td></td></tr>

                <tr><td>Národní muzeum v kapse</td><td>Futured / Národní muzeum, More.is.More</td><td>Národní muzeum</td><td>Veřejný zadavatel</td><td><span className="oranzovy bold">zlato</span></td></tr>

                <tr><td>Pozvánka do světa peněz</td><td>Agentura Gen</td><td>Česká národní banka</td><td>Veřejný zadavatel</td><td><span className="oranzovy bold">stříbro</span></td></tr>

                <tr><td>System Sho(p)k</td><td>McCann Prague</td><td>Všeobecná zdravotní pojišťovna</td><td>Veřejný zadavatel</td><td><span className="oranzovy bold">stříbro</span></td></tr>

                <tr><td>Kampaň na datové schránky</td><td>Media Age</td><td>Ministerstvo vnitra</td><td>Veřejný zadavatel</td><td><span className="oranzovy bold">bronz</span></td></tr>

                <tr><td>Dárek, který chutná</td><td>Momentum Czech Republic</td><td>Státní zemědělský intervenční fond</td><td>Veřejný zadavatel</td><td><span className="oranzovy bold">bronz</span></td></tr>

                <tr><td>Kupujte BIO: Posílení značky v náročném roce</td><td>Media Age</td><td>Státní zemědělský intervenční fond</td><td>Veřejný zadavatel</td><td><span className="oranzovy bold">bronz</span></td></tr>

            </table>

        </div>


    </section>
}