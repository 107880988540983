import {isMobile} from '../util';
import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';

export function Menu() {
    return isMobile ?
        <MobileMenu/> :
        <DesktopMenu/>
}

function MobileMenuItem({pageId, label, onClick}: { pageId: string, label: string, onClick: () => void }) {
    return <div className="navText">
        <a href={`#${pageId}`} onClick={onClick}>{label}</a>
    </div>
}

function MobileMenu() {
    const [open, setOpen] = useState(false)

    function close() {
        setOpen(false)
    }

    return <>
        <div id="mainMenu" className={`navigation-flexbox ${open ? 'otevrene' : ''}`}>
            <MobileMenuItem pageId="terminy" label="Termíny" onClick={close}/>
            <MobileMenuItem pageId="poplatky" label="Poplatky" onClick={close}/>
            <MobileMenuItem pageId="kategorie" label="Kategorie" onClick={close}/>
            <MobileMenuItem pageId="pravidla" label="Pravidla" onClick={close}/>
            <MobileMenuItem pageId="postup" label="Postup přihlášení" onClick={close}/>
            <MobileMenuItem pageId="kontakt" label="Kontakt" onClick={close}/>
        </div>
        <img
            className="mobile-nav-icon"
            src={open ? 'images/prvky/menu_zavrit.svg' : 'images/prvky/menu.svg'}
            alt=""
            onClick={() => setOpen(!open)}
        />
    </>
}

function MenuItem({popis, to}: { popis: string, to: string }) {
    return <div className="navitem">
        <SubmenuItem popis={popis} to={to}/>
    </div>
}

function SubmenuItem({popis, to}: { popis: string, to: string }) {
    return <Link className="navText" to={to}>{popis}</Link>
}

function Submenu({popis, to, children}: { popis: string, to: string, children: React.ReactNode }) {
    const [hoverCount, setHoverCount] = useState(0)
    const hideTimeout = useRef<NodeJS.Timeout>();

    function enter() {
        console.log('ENTER')
        if (hideTimeout.current) {
            clearTimeout(hideTimeout.current)
        }
        setHoverCount(o => o + 1)
    }

    function leave() {
        console.log('LEAVE')
        hideTimeout.current = setTimeout(() => setHoverCount(o => o - 1), 0)
    }

    return <div className="navitem"
                onMouseEnter={enter}
                onMouseLeave={leave}>
        <Link className="navText" to={to}>{popis}</Link>
        {hoverCount > 0 &&
            <div className="navsubmenu"
                 onMouseEnter={enter}
                 onMouseLeave={leave}>
                {children}
            </div>}
    </div>
}

function DesktopMenu() {
    /**
     * Odkazy tady nahrazuje Link, který je součástí našeho routování, díky kterému se nebude stránka načítat pokaždé, když změníme URL
     */
    return <div className="navigation-flexbox">
        <MenuItem popis="O nás" to="/onas"/>
        <Submenu popis="Soutěž" to="/soutez">
            <SubmenuItem to="/pravidla" popis="pravidla & postup"/>
            <SubmenuItem to="/terminy" popis="termíny & poplatky"/>
            <SubmenuItem to="/kategorie" popis="kategorie"/>
            <SubmenuItem to="/porota" popis="porota"/>
        </Submenu>
        <MenuItem to="/vysledky" popis="výsledky"/>
        {/* <Submenu popis="Galerie" to="/galerie">
            <SubmenuItem to="/vysledky" popis="výsledky"/>
            <SubmenuItem to="/galerie" popis="interaktivní galerie"/>
        </Submenu> */}
        <MenuItem popis="kontakt" to="/kontakt"/>
        <div className="navText navitem">
            <Link
                id="registrace"
                to="https://www.imcczechawards.cz"
                target="_blank"
                rel="noreferrer">
                registrace
            </Link>
        </div>
    </div>
}