import React from 'react';

export function Paticka() {
    return <footer>
        <div className="container">
            <div className="footer-flexbox">
                <div className="footer-social">
                    <a href="https://www.facebook.com/IMPACTCzechAwards">
                        <img src="images/loga/facebook.svg" alt="" />
                    </a>
                    <a href="https://www.instagram.com/imc_czech_awards_official/">
                        <img src="images/loga/instagram.svg" alt="" />
                    </a>
                    <a href="https://www.linkedin.com/company/impactczechawards/">
                        <img src="images/loga/linkedin.svg" alt="" />
                    </a>
                </div>
                <div className="footer-partners">
                    partneři
                    <a href="https://admez.cz/">
                        <img src="images/loga/admez.png" alt="" />
                    </a>
                    <a href="https://www.aka.cz/">
                        <img src="images/loga/akka.png" alt="" />
                    </a>
                    <a href="https://eaca.eu/">
                        <img src="images/loga/eaca.png" alt="" />
                    </a>
                    <a href="https://www.mam.cz/">
                        <img src="images/loga/MAM.png" alt="" />
                    </a>
                </div>
            </div>
        </div>
    </footer>
}