import React from 'react';
import {Menu} from './Menu';
import {Link} from 'react-router-dom';
import {isMobile} from '../util';

export function Hlavicka() {
    return <header>
        <div className="container">
            <div className="header-flexbox">
                <div className="navText modry" id="navUvod">
                    {isMobile ?
                        <a href="#uvod">
                            <img
                                className="logo"
                                src="images/loga/ImpactAwards.png"
                                alt=""
                            />
                        </a> : <Link to="/">
                            <img
                                className="logo"
                                src="images/loga/ImpactAwards.png"
                                alt=""/>
                        </Link>}
                </div>
                <Menu/>
            </div>
        </div>
    </header>
}